// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authUrl: 'https://api.waveapps.com/oauth2/authorize',
  clientId: 'YDC9i-DWuZTL2K7gTRJwCjHbD1tXqIHwBPbyyQnd',
  queryUrl: 'https://gql.waveapps.com/graphql/public',
  firebase: {
    apiKey: 'AIzaSyC6JUV-JEWkot3IhyjPYlNAsTAEVi0xyt4',
    authDomain: 'cinestage-finance.firebaseapp.com',
    projectId: 'cinestage-finance',
    storageBucket: 'cinestage-finance.appspot.com',
    messagingSenderId: '794070325380',
    appId: '1:794070325380:web:38cdf01d9258bfc41e8ad1',
    measurementId: 'G-X9FK7JPHQW',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
